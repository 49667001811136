import axios from "axios";
import browserStorageService from "../services/browserStorageService";
import authService from "../services/authService";
import { STORAGE_KEY } from "../../constants/browserStorageConsts";

const getAuthToken = () => {
  const token = browserStorageService.getAccessToken();
  if (token) {
    return `Bearer ${token}`;
  }
  return null;
};

axios.defaults.baseURL = window._env_.REACT_APP_BASE_URL;
axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";

axios.interceptors.request.use((request) => {
  if (request.url !== "connect/token") {
    const isTokenRefreshing = !browserStorageService.getIsRefreshingToken();
    if (!isTokenRefreshing) {
      sessionStorage.setItem(STORAGE_KEY.IS_REFRESHING, "true");

      authService
        .manageTokenLifetime()
        .finally(() =>
          sessionStorage.setItem(STORAGE_KEY.IS_REFRESHING, "false")
        );
    }

    const authToken = getAuthToken();
    if (authToken && request.headers) {
      request.headers.Authorization = authToken;
    }
    if (request.baseURL) {
      request.baseURL += "/api/v1";
    }
  }

  return request;
});
